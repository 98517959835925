import Avatar from 'Views/Component/Avatar';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdPersonPin,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { Redirect } from 'react-router-dom';
import { API_URL } from '../../constants'
import axios from 'axios'

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    redirectOut: false,
    redirectProfile: false,
    icon: {},
    username: ''
  };

  toggleNotificationPopover = event => {
    event.preventDefault();
    
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = event => {
    event.preventDefault();
    
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
 
  onProfile = () => {
    this.setState({
      redirectProfile: true
    });
  }

  onLogout = event => {
    event.preventDefault();
    
    localStorage.clear()
    if(localStorage.getItem('auth') === null){
      this.setState({
        redirectOut: true
      });
    }
  }

  componentWillMount() {
    this.setState({ 
      auth: JSON.parse(localStorage.getItem('auth')) 
    })        
  }

  componentDidMount() {
    const login = this.state.auth
    
    if(  login !== null ){
      this.getDetailMembers()
    }
  }
  
  getDetailMembers = async () => {
    const login = this.state.auth
    
    await axios.get(`${API_URL}/master/members/${login.userId}`, {
      headers : {
        Authorization: 'Bearer ' + login.access_token
      }
    })
    .then(res => {
      let row = res.data.data
      
      this.setState({ username: row.name })
      this.setState({ icon: row.attachments[0] })
    })
    .catch(error => {
      if (error) {
        console.log( "Error : " + error )
      }
    })
  }
  
  render() {
    if( this.state.redirectProfile === true ){
      return (<Redirect exact to="/profile" />)
    }
    
    if ( this.state.redirectOut === true || localStorage.getItem('auth') === null ){
      return (<Redirect exact from="/dashboard" to="/login" />)
    }

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline color="greentea" onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              { 
                this.state.icon !== null ? 
                  <Avatar
                    onClick={this.toggleUserCardPopover}
                    className="can-click"
                    attach={this.state.icon}
                    username={this.state.username}
                  />
                : ""
              }
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">    
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light" onClick={ this.onProfile }>
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light" onClick={ this.onLogout }>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;

import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Form,
  Col,
  Row,
  Input,
  FormGroup,
  Button,
} from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CssBaseline from '@material-ui/core/CssBaseline';
import logo200Image from 'assets/logo/logo_abzen.png';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';
import SourceLink from '../Component/SourceLink';
import { Redirect } from 'react-router-dom';
import { API_URL } from '../../constants';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import '../../styles/login.scss';

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default function SignIn() {
  const username = useFormInput('');
  const password = useFormInput('');
  const [errMessage, setErrMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleLogin = async event => {
    event.preventDefault();

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:
        'username=' +
        username.value +
        '&password=' +
        password.value +
        '&grant_type=password',
    };

    const api = await fetch(`${API_URL}/auth/login`, requestOptions);
    const res = await api.json();

    if (api.ok) {
      localStorage.clear();
      localStorage.setItem('auth', JSON.stringify(res));
      console.log(JSON.stringify(res));
      let bearer = JSON.parse(localStorage.getItem('auth'));
      console.log(bearer.access_token);
      let access_token = bearer.access_token;
      var requestOptionsMe = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      };
      const api = await fetch(
        `${API_URL}/master/absent/main/me`,
        requestOptionsMe,
      );
      const resMe = await api.json();
      localStorage.setItem('profil', JSON.stringify(resMe.data));
      setRedirect(true);
      setOpen(false);
    } else {
      setErrMessage(res.error);
      setOpen(true);
    }
  };

  if (redirect === true) {
    return window.location.reload();
  } else if (localStorage.getItem('auth') !== null) {
    return <Redirect exact from="/login" to="/dashboard" />;
  }

  return (
    <div className="container-fluid">
      <Row className="align-items-center justify-content-center bg-left">
        <Col lg={10}>
          <Row>
            <Col lg={7} className="bg-dangerx d-none d-lg-block">
              <div className="px-5 text-white">
                <SourceLink className="navbar-brand mb-4">
                  <img src={logo200Image} width="360" height="120" alt="Logo" />
                </SourceLink>

                <p class="lead">
                  Abzen mengintegrasikan Teknologi IoT dan Sistem Pemrosesan
                  Gambar yang memungkinkan fitur pengenalan wajah, deteksi
                  gerakan khusus, dan sistem pemantauan tanda yang tertanam
                  dalam sistem kamera pintar Abzen atau melalui aplikasi pada
                  kamera Anda, menjadikannya sistem yang andal dan kompatibel
                  dengan proses bisnis internal Anda.
                </p>
              </div>
            </Col>
            <Col lg={5} className="bg-warningx">
              <Row className="my-3">
                <Col>
                  <Card style={{ borderRadius: '10px' }}>
                    <CardBody className="p-5">
                      <Form onSubmit={handleLogin} className="">
                        <Row className="text-center">
                          <Col>
                            <h4>
                              <span style={{ color: '#197163' }}>
                                Sign In Account!
                              </span>
                            </h4>
                            <p className="mb-5" style={{ color: '#767c77' }}>
                              Abzen - Easy Online Attendance
                            </p>
                          </Col>
                        </Row>

                        <FormGroup>
                          <Input
                            placeholder="Username"
                            type="text"
                            name="username"
                            id="username"
                            {...username}
                            autoComplete="off"
                            bsSize="lg"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Input
                            placeholder="Password"
                            type="password"
                            name="password"
                            id="password"
                            {...password}
                            autoComplete="off"
                            bsSize="lg"
                          />
                        </FormGroup>

                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Button
                            type="submit"
                            variant="contained"
                            style={{ background: '#197163' }}
                            className="text-light"
                            color="link"
                            size="lg"
                            outline
                            block
                          >
                            Sign In
                          </Button>
                        </FormGroup>

                        <Grid container>
                          <Grid item xs>
                            <Link
                              href="/enrollment"
                              variant="body2"
                              style={{ color: '#197163' }}
                            >
                              Self Enrollment ?
                            </Link>
                          </Grid>
                        </Grid>

                        <Snackbar
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          open={open}
                          autoHideDuration={3000}
                          onClose={handleClose}
                          message={errMessage}
                        />
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

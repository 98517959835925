import React, { useState, useEffect, useMemo } from 'react';
import { MdWorkspaces } from 'react-icons/md';

let login = localStorage.getItem('auth');
let auth = JSON.parse(login);

export let dataAuth = auth;
export let token = auth !== null ? auth.access_token : auth;
export let superadmin =
  auth !== null ? (auth.roles[0] === 'SUPERADMIN' ? true : false) : false;
export const API_URL = 'http://abzen.rastek.id:8080/restapi'; // http://157.230.44.26
//export const API_DEV    = 'http://192.168.10.167:8080'
export const API_PLUS = 'http://abzen-plus.rastek.id:8081';

export const Loader = (
  <>
    <div className="p-3">
      <MdWorkspaces className="rotating h5 m-0" />{' '}
      <small className="ml-1"> Loading ...</small>
    </div>
  </>
);

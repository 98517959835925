import React, { useState, useEffect } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import logo200Image from 'assets/logo/logo_abzen.png';
import SourceLink from 'Views/Component/SourceLink';
import { HiUserGroup } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { API_URL } from '../../constants';
import bn from 'utils/bemnames';
import axios from 'axios';
import {
  MdKeyboardArrowDown,
  MdDashboard,
  MdFingerprint,
  MdPeople,
  MdPerson,
  MdMiscellaneousServices,
  MdSettings,
  MdCalendarViewMonth,
  MdSwapHorizontalCircle,
} from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';
import { lighten } from '@material-ui/core';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');
// const logina = this.state.auth;
const navItems = [
  { to: '/dashboard', name: 'dashboard', exact: false, Icon: MdDashboard },
  { to: '/absens', name: 'kehadiran', exact: true, Icon: MdFingerprint },

  { to: '/calendar', name: 'kalender', exact: true, Icon: MdCalendarViewMonth },
  {
    to: '/visitors',
    name: 'pengunjung',
    exact: true,
    Icon: MdPeople,
  },
];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenApps: false,
      isOpenTest: false,
      isOpenDevices: false,
      isOpenPages: false,
      auth: null,
      logo: null,
      navMenu: [],
      navMenuKaryawan: [],
    };
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  componentWillMount() {
    this.setState({
      auth: JSON.parse(localStorage.getItem('auth')),
    });
  }

  componentDidMount() {
    const login = this.state.auth;
    const navApp = [];
    const navTest = [];

    if (login !== null) {
      this.getDetailOrganizations();
      navTest.push({
        to: '/underConstruct',
        name: 'permohonanKasbon',
        judul: 'Permohonan Kasbon',
        exact: false,
        Icon: GoPrimitiveDot,
      });
      navTest.push({
        to: '/underConstruct',
        name: 'permohonanPengembalian',
        judul: 'Permohonan Pengembalian',
        exact: false,
        Icon: GoPrimitiveDot,
      });
      if (login.roles[0] !== 'ADMIN_WEB') {
        navApp.push({
          to: '/members',
          name: 'members',
          exact: false,
          Icon: GoPrimitiveDot,
        });
        navApp.push({
          to: '/departments',
          name: 'departments',
          exact: false,
          Icon: GoPrimitiveDot,
        });
        navApp.push({
          to: '/shifts',
          name: 'shifts',
          exact: false,
          Icon: GoPrimitiveDot,
        });
        navApp.push({
          to: '/task',
          name: 'task',
          exact: false,
          Icon: GoPrimitiveDot,
        });
      }

      // if (login.roles[0] === 'APPROVER') {
      //   navItems.push({
      //     to: '/approval',
      //     name: 'perubahan jadwal',
      //     exact: true,
      //     Icon: MdSwapHorizontalCircle,
      //   });
      // }

      if (login.roles[0] === 'SUPERADMIN') {
        // navApp.push({
        //   to: '/clusters',
        //   name: 'clusters',
        //   exact: false,
        //   Icon: GoPrimitiveDot,
        // });
        navApp.push({
          to: '/organizations',
          name: 'organizations',
          exact: false,
          Icon: GoPrimitiveDot,
        });
        navApp.push({
          to: '/roles',
          name: 'roles',
          exact: false,
          Icon: GoPrimitiveDot,
        });

        // navApp.push({
        //   to: '/master-device',
        //   name: 'device',
        //   exact: false,
        //   Icon: GoPrimitiveDot,
        // });
      }
    }

    this.setState({
      navMenu: navApp,
      navMenuKaryawan: navTest,
    });
  }

  getDetailOrganizations = async () => {
    const login = this.state.auth;

    await axios
      .get(`${API_URL}/master/organizations/${login.organizationId}`, {
        headers: {
          Authorization: 'Bearer ' + login.access_token,
        },
      })
      .then(res => {
        let row = res.data.data;

        this.setState({
          logo: row.logo,
        });
      })
      .catch(error => {
        if (error) {
          console.log('Error : ' + error);
        }
      });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar className="d-flex justify-content-center">
            <SourceLink
              className="navbar-brand mr-0 my-3"
              style={{
                width: '75%',
              }}
            >
              <img
                src={
                  this.state.logo !== null
                    ? 'data:image/png;base64,' + this.state.logo
                    : logo200Image
                }
                alt="Logo"
                className="img-fluid"
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Test')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPerson className={bem.e('nav-item-icon')} />
                  <span className="align-self-start">Karyawan</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenTest
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenTest}>
              {this.state.navMenuKaryawan.map(
                ({ to, name, judul, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{judul}</span>
                    </BSNavLink>
                  </NavItem>
                ),
              )}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Apps')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSettings className={bem.e('nav-item-icon')} />
                  <span className="align-self-start">Configurations</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenApps
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenApps}>
              {this.state.navMenu.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;

import React, { useState, useEffect } from 'react'
import { Alert, Form, Col, Row, Input, FormGroup, FormText, Button, Label } from 'reactstrap'
import Autocomplete from "@material-ui/lab/Autocomplete"
import CssBaseline from '@material-ui/core/CssBaseline'
import logo200Image from 'assets/logo/logo_abzen.png'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import SourceLink from '../Component/SourceLink'
import { Redirect } from 'react-router-dom'
import { API_URL } from '../../constants'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import '../../styles/login.scss'
import axios from 'axios'

const Field = ({name, nameLabel, type, placeholder, disabled, value, onChange }) => {
  return (
      <FormGroup style={{ textAlign : 'left' }}>
          <Label for={name} style={{textTransform: 'capitalize'}}>{nameLabel}</Label>
          <Input type={type} name={name} id={name} value={value} placeholder={placeholder} disabled={disabled} onChange={onChange} autoComplete="off" />
      </FormGroup>
  )
}

const FieldCek = ({name, nameLabel, type, placeholder, disabled, value, onChange, onClick }) => {
  return (
      <FormGroup style={{ textAlign : 'left' }}>
          <Label for={name} style={{textTransform: 'capitalize'}}>{nameLabel}</Label>
          <Row>
            <Col xs="9">
              <Input type={type} name={name} id={name} value={value} placeholder={placeholder} disabled={disabled} onChange={onChange} autoComplete="off" />
            </Col>
            <Col xs="3">
              <Button variant="contained" onClick={onClick} style={{background : "#197163"}} className="text-light" color="link" outline block>
                Cek
              </Button>

            </Col>
          </Row>
          
      </FormGroup>
  )
}

const FieldFile = ({name, nameLabel, disabled, onFile, refinput}) => (
  <FormGroup style={{ textAlign : 'left' }}>
      <Label for={name}>{nameLabel}</Label>
      <input type="file" name={name} id={name} disabled={disabled} accept="image/*" ref={refinput} onChange={onFile} style={{ padding: 0, height: '35px' }}/>
  </FormGroup>
)

export default function SignUp() {
  const [errMessage, setErrMessage]               = useState('')
  const [open, setOpen]                           = useState(false)
  const [redirect, setRedirect]                   = useState(false)
  
  const [nip, setNIP]                             = useState('')
  const [names, setNames]                         = useState('')
  const [username, setUsername]                   = useState('')
  const [password, setPassword]                   = useState('')
  const [email, setEmail]                         = useState('')
  const [attach1, setAttach1]                     = useState(null)
  const [attach2, setAttach2]                     = useState(null)
  const [attach3, setAttach3]                     = useState(null)
  const [attach4, setAttach4]                     = useState(null)
  const [attach5, setAttach5]                     = useState(null)
  const [attach6, setAttach6]                     = useState(null)
  const [organisasi, setOrganisasi]               = useState('')
  const [organisasiNotif, setOrganisasiNotif]     = useState(null)
  const [nipNotif, setNIPNotif]                   = useState(null)

  const [clusterId, setCluster]                   = useState('')
  const [clusterData, setClusterData]             = useState([])
  const [organizationId, setOrganization]         = useState('')
  const [organizationData, setOrganizationData]   = useState([])
  
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const getCluster = async event => {
  //   await axios.get(`${API_URL}/master/dashboard/clusters?organizationId=${organizationId}${ event !== undefined ? '&name=' + event.target.value : '' }`)
  //   .then(res => {
  //     let row = res.data.data
          
  //     setClusterData(row)
  //   })
  //   .catch(error => {
  //     setClusterData([])
  //     if (error) {
  //       console.log( "Error : " + error )
  //     }
  //   })
  // }

  // const getOrganization = async event => {
  //   await axios.get(`${API_URL}/master/dashboard/organizations${ event !== undefined ?  '?name=' + event.target.value : '' }`)
  //   .then(res => {
  //     let row = res.data.data
      
  //     // setOrganizationData(row)
  //   })
  //   .catch(error => {
  //     setOrganizationData([])
  //     if (error) {
  //       console.log( "Error : " + error )
  //     }
  //   })
  // }

  const getOrganization = async () => {
    await axios.get(`${API_URL}/master/v1/pub/cluster${organisasi !== '' ? '?name=' + organisasi : '' }`)
    .then(res => {
      let row = res.data.data
      if( typeof row !== 'undefined' && row !== null ){
        setOrganisasiNotif(true)
      }else{
        setOrganisasiNotif(false)
      }
    })
    .catch(error => {
      setOrganizationData([])
      if (error) {
        setOrganisasiNotif(false)
        console.log( "Error : " + error )
      }
    })
  }

  const getNIP = async () => {
    await axios.get(`${API_URL}/master/members/check${nip !== '' ? '?nip=' + nip : '' }${organisasi !== '' ? '&clusterName=' + organisasi : '' }`)
    .then(res => {
      let row = res.data.data
      if( typeof row !== 'undefined' && row !== null ){
        setNIPNotif(true)
      }else{
        setNIPNotif(false)
      }
    })
    .catch(error => {
      setOrganizationData([])
      if (error) {
        setNIPNotif(false)
        console.log( "Error : " + error )
      }
    })
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    let attach = []

    // if( attach1 !== null ){ attach.push({ "category": "DEPAN TIDAK KACAMATA", "file_content": attach1 })}
    // if( attach2 !== null ){ attach.push({ "category": "SAMPING KANAN TIDAK KACAMATA", "file_content": attach2 })}
    // if( attach3 !== null ){ attach.push({ "category": "SAMPING KIRI TIDAK KACAMATA", "file_content": attach3 })}
    // if( attach4 !== null ){ attach.push({ "category": "DEPAN BERKACAMATA", "file_content": attach4 })}
    // if( attach5 !== null ){ attach.push({ "category": "SAMPING KANAN BERKACAMATA", "file_content": attach5 })}
    // if( attach6 !== null ){ attach.push({ "category": "SAMPING KIRI BERKACAMATA", "file_content": attach6 })}
    
    if( attach1 !== null ){ attach.push({ "category": "FRONT", "file_content": attach1 })}
    if( attach2 !== null ){ attach.push({ "category": "RIGHT", "file_content": attach2 })}
    if( attach3 !== null ){ attach.push({ "category": "LEFT", "file_content": attach3 })}
    if( attach4 !== null ){ attach.push({ "category": "GLASSES_FRONT", "file_content": attach4 })}
    if( attach5 !== null ){ attach.push({ "category": "GLASSES_RIGHT", "file_content": attach5 })}
    if( attach6 !== null ){ attach.push({ "category": "GLASSES_LEFT", "file_content": attach6 })}
    
    let dataval = {
      "user_name": username,
      "password": password,
      "nip": nip,
      "email": email,
      "name": names,
      "cluster_name": organisasi,
      // "organization_id": organizationId,
      // "cluster_id": clusterId,
      "attachments": attach
    }

    // await axios.post(`${API_URL}/master/absent/main/enrollment`, dataval)
    await axios.post(`${API_URL}/master/v1/pub/enrollment`, dataval)
    .then(res => {
      let message = ''
    
      if( res.statusText === "OK" ){
        message = <Alert color="success">Data berhasil disimpan.</Alert>
      }else{
        message = <Alert color="danger">Data gagal disimpan, periksa kembali masukan anda.</Alert>
      }
      
      setErrMessage(message)
      setOpen(false);
      setTimeout(() => {
        setRedirect(true)
      }, 5000);
    })
    .catch(error => {
      if (error) {          
        setErrMessage(error)
        setOpen(true);
        console.log( "Error : " + error )
      }
    })
  }
  
  const handleUpload1 = e => {
    let reader = new FileReader()
    
    const files = e.target.files;
    const file = files[0];
    
    reader.readAsDataURL(file)
    reader.onload = () => {
      setAttach1(reader.result)
    }
  }

  const handleUpload2 = e => {
    let reader = new FileReader()
    
    const files = e.target.files;
    const file = files[0];
    
    reader.readAsDataURL(file)
    reader.onload = () => {
      setAttach2(reader.result)
    }
  }
  
  const handleUpload3 = e => {
    let reader = new FileReader()
    
    const files = e.target.files;
    const file = files[0];
    
    reader.readAsDataURL(file)
    reader.onload = () => {
      setAttach3(reader.result)
    }
  }
  
  const handleUpload4 = e => {
    let reader = new FileReader()
    
    const files = e.target.files;
    const file = files[0];
    
    reader.readAsDataURL(file)
    reader.onload = () => {
      setAttach4(reader.result)
    }
  }
  
  const handleUpload5 = e => {
    let reader = new FileReader()
    
    const files = e.target.files;
    const file = files[0];
    
    reader.readAsDataURL(file)
    reader.onload = () => {
      setAttach5(reader.result)
    }
  }

  const handleUpload6 = e => {
    let reader = new FileReader()
    
    const files = e.target.files;
    const file = files[0];
    
    reader.readAsDataURL(file)
    reader.onload = () => {
      setAttach6(reader.result)
    }
  }
  
  const handleCekOrganization = async () => {
    if( organisasi !== ''){
      getOrganization()
    }
  }

  const handleCekNIP = async () => {
    if( organisasi !== '' && nip !== ''){
      getNIP()
    }
  }

  // useEffect(() => {
  //   getCluster()
  // }, [organizationId])

  
  // useEffect(() => {
  //   if( organisasi !== ''){
  //     getOrganization()
  //   }
  // }, [organisasi])

  useEffect(() => {
    // getCluster()
    // getOrganization()
  }, [])

  if ( redirect === true ){
    return (<Redirect exact from="/enrollment" to="/login" />)
  }

  return (
    <Container component="main">
      <CssBaseline />
      
      <div className="session">  
        <div className="left">
          <Row className="text-center">
            <Col md={12} lg={12} className="p-5">
              <div className="eula">
                <SourceLink className="navbar-brand mb-4">
                  <img
                    src={logo200Image}
                    width="360"
                    height="120"
                    alt="Logo"
                    />
                </SourceLink>

                <h1 className="mb-4">WELCOME TO ABZEN</h1>

                <span>
                  Abzen mengintegrasikan Teknologi IoT dan Sistem Pemrosesan Gambar yang memungkinkan fitur pengenalan wajah, 
                  deteksi gerakan khusus, dan sistem pemantauan tanda yang tertanam dalam sistem kamera pintar Abzen atau melalui aplikasi pada kamera Anda,  
                  menjadikannya sistem yang andal dan kompatibel dengan proses bisnis internal Anda.
                </span>
              </div>
            </Col>
          </Row>
        </div>
        
        <Form onSubmit={ handleLogin } className="log-in" style={{ width: '50%', paddingTop: '20px' }}>
          <Row className="text-center">
            <Col xs={12} style={{ borderBottom: '1px solid #d4d4d4', marginBottom: '10px' }}>
              <h4><span style={{color: "#197163"}}>Self Enrollment Account!</span></h4>
            </Col>
            
            <Col xs={6}>
              <FieldCek name="organisasi" nameLabel="Organisasi" type="text" placeholder="ex : ORG" value={organisasi} onChange={e => setOrganisasi(e.target.value) } onClick={ handleCekOrganization } />
              <p style={{ color: organisasiNotif ? '#4caf50' : '#f44336', display: organisasiNotif == null ? 'none' : 'block' }}>* { organisasiNotif ? 'Data Organization Available' : 'Data Organization Not Available' } *</p>
              <FieldCek name="nip" nameLabel="NIP" type="text" placeholder="ex : 361xxxxxx" value={nip} disabled={ organisasiNotif ? '' : 'disabled' } onChange={e => setNIP(e.target.value) } onClick={ handleCekNIP } />
              <p style={{ color: nipNotif ? '#4caf50' : '#f44336', display: nipNotif == null ? 'none' : 'block' }}>* { nipNotif ? 'Data NIP Available' : 'Data NIP Not Available' } *</p>
              <Field name="names" nameLabel="Name" type="text" placeholder="ex : abzen id" value={names} disabled={ nipNotif ? '' : 'disabled' } onChange={e => setNames(e.target.value) } />
              <Field name="username" nameLabel="Username" type="text" placeholder="ex: abzenid" value={username} disabled={ nipNotif ? '' : 'disabled' } onChange={e => setUsername(e.target.value) } />
              <Field name="password" nameLabel="Password" type="password" placeholder="ex : *********" value={password} disabled={ nipNotif ? '' : 'disabled' } onChange={e => setPassword(e.target.value) } />
              <Field name="email" nameLabel="Email" type="email" placeholder="ex : example@abzen.id" value={email} disabled={ nipNotif ? '' : 'disabled' } onChange={e => setEmail(e.target.value) } />
          
              {/* <FormGroup style={{ textAlign : 'left' }}>
                <Label for="organizationId" style={{ textTransform: 'capitalize' }}>Organization</Label>            
                <Autocomplete
                    id="organizationId"
                    name='organizationId'
                    options={organizationData}
                    getOptionLabel={option => option !== undefined ? option.name : option }
                    defaultValue={organizationId}
                    style={{ width: '100%' }}
                    onChange={ (keys, val) => val !== null ? setOrganization(val.id) : '' }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            onChange={getOrganization}
                        />
                    )}
                />
              </FormGroup>
              
              <FormGroup style={{ textAlign : 'left' }}>
                <Label for="clusterId" style={{ textTransform: 'capitalize' }}>Cluster</Label>
                <Autocomplete
                    id="clusterId"
                    name='clusterId'
                    options={clusterData}
                    getOptionLabel={option => option !== undefined ? option.name : option }
                    defaultValue={clusterId}
                    style={{ width: '100%' }}
                    onChange={ (keys, val) => val !== null ? setCluster(val.id) : '' }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            onChange={getCluster}
                        />
                    )}
                />
              </FormGroup> */}
            </Col>

            <Col xs={6}>
              <FormGroup style={{ margin: 0, textAlign: 'left', borderBottom: '1px solid #d8d8d8', marginBottom: '10px'}}>
                <Label for="attachment0" style={{ textTransform: 'capitalize' }}>Attachment Tidak Berkacamata</Label>            
              </FormGroup>
              <FieldFile name="attachment1" nameLabel="Foto Depan" description="Min 1 Max 6" onFile={handleUpload1} />
              <FieldFile name="attachment2" nameLabel="Foto Samping Kanan" description="Min 1 Max 6" onFile={handleUpload2} />
              <FieldFile name="attachment3" nameLabel="Foto Samping Kiri" description="Min 1 Max 6" onFile={handleUpload3} />
            
              <FormGroup style={{ margin: 0, textAlign: 'left', borderBottom: '1px solid #d8d8d8', marginBottom: '10px', marginTop: '35px' }}>
                <Label for="attachment7" style={{ textTransform: 'capitalize' }}>Attachment Berkacamata</Label>            
              </FormGroup>
              <FieldFile name="attachment4" nameLabel="Foto Depan" description="Min 1 Max 6" onFile={handleUpload4} />
              <FieldFile name="attachment5" nameLabel="Foto Samping Kanan" description="Min 1 Max 6" onFile={handleUpload5} />
              <FieldFile name="attachment6" nameLabel="Foto Samping Kiri" description="Min 1 Max 6" onFile={handleUpload6} />
            </Col>
          </Row>

          <FormGroup>
            <Link href="/login" variant="body2" style={{color: "#197163", marginBottom: "10px" }}>
              Back To Sign In ?
            </Link>
            
            <br /><br />
            <Button type="submit" variant="contained" style={{background : "#197163"}} className="text-light" color="link" outline block>
              Enrollment
            </Button>
          </FormGroup>
          
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            message={errMessage}
          />
        </Form>
      </div>
   </Container>
  );
}
import GAListener from 'Views/Component/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'Views/Layout';
import SignIn from 'Views/Auth/SignIn';
import SignUp from 'Views/Auth/SignUp';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

// Pages
const DashboardPage = React.lazy(() => import('Views/DashboardPage'));
const AbsensPage = React.lazy(() => import('Views/Absens'));
const VisitorsPage = React.lazy(() => import('Views/Visitors'));
const MembersPage = React.lazy(() => import('Views/Members'));
const ClustersPage = React.lazy(() => import('Views/Clusters'));
const DepartmentsPage = React.lazy(() => import('Views/Departments'));
const OrganizationsPage = React.lazy(() => import('Views/Organizations'));
const RolesPage = React.lazy(() => import('Views/Roles'));
const ProfilePage = React.lazy(() => import('Views/Profile'));
const ShiftsPage = React.lazy(() => import('Views/Shifts'));
const MasterDevicePage = React.lazy(() => import('Views/MasterDevice'));
const CalendarPage = React.lazy(() => import('Views/Calendar/Calendar.js'));
const ApprovalPage = React.lazy(() => import('Views/Approval/Approval.js'));
const DetailCalendar = React.lazy(() =>
  import('Views/DetailCalendar/DetailCalendar.js'),
);
const SettingShifts = React.lazy(() =>
  import('Views/SettingShifts/SettingShifts.js'),
);
const Task = React.lazy(() => import('Views/Task/Task.js'));
const UnderConstruct = React.lazy(() => import('Views/underConstruct.js'));

// console.log(enData);

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => <SignIn {...props} />}
            />
            <LayoutRoute
              exact
              path="/enrollment"
              layout={EmptyLayout}
              component={props => <SignUp {...props} />}
            />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback>
                <Route exact path="/" render={() => <DashboardPage />} />
                <Route
                  exact
                  path="/dashboard"
                  render={() => <DashboardPage />}
                />
                <Route
                  exact
                  path="/underConstruct"
                  render={() => <UnderConstruct />}
                />
                <Route exact path="/absens" render={() => <AbsensPage />} />
                <Route exact path="/calendar" render={() => <CalendarPage />} />
                <Route exact path="/visitors" render={() => <VisitorsPage />} />
                <Route exact path="/members" render={() => <MembersPage />} />
                <Route exact path="/clusters" render={() => <ClustersPage />} />
                <Route exact path="/approval" render={() => <ApprovalPage />} />
                <Route
                  exact
                  path="/departments"
                  render={() => <DepartmentsPage />}
                />
                <Route
                  exact
                  path="/organizations"
                  render={() => <OrganizationsPage />}
                />
                <Route exact path="/roles" render={() => <RolesPage />} />
                <Route exact path="/profile" render={() => <ProfilePage />} />
                <Route exact path="/shifts" render={() => <ShiftsPage />} />
                <Route
                  exact
                  path="/detail-day/:id"
                  render={() => <DetailCalendar />}
                />
                <Route
                  exact
                  path="/setting-shifts/:id"
                  render={() => <SettingShifts />}
                />
                <Route
                  exact
                  path="/master-device"
                  render={() => <MasterDevicePage />}
                />
                <Route exact path="/task" render={() => <Task />} />
              </React.Suspense>
            </MainLayout>

            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
